import { handleErrorWithSentry } from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';
import * as Sentry from '@sentry/sveltekit';

if (import.meta.env.PROD) {
	Sentry.init({
		dsn: 'https://c52d0ff5014d480f010a81e0d21eb424@tracking.8s.de/55',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [Sentry.replayIntegration()]
	});
	if (env.PUBLIC_ENDPOINT) Sentry.setTag('8s.instance', env.PUBLIC_ENDPOINT);
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
