import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0,4,2,3];

export const dictionary = {
		"/(unauthenticated)/auth/activate": [21,[4,5]],
		"/(unauthenticated)/auth/confirmation_screen": [22,[4,5]],
		"/(unauthenticated)/auth/forgot": [23,[4,5]],
		"/(unauthenticated)/auth/maintenance": [24,[4,5]],
		"/(unauthenticated)/auth/reset": [25,[4,5]],
		"/(unauthenticated)/auth/sign_in": [26,[4,5]],
		"/(unauthenticated)/auth/sign_up": [~27,[4,5]],
		"/(unauthenticated)/auth/unlock": [28,[4,5]],
		"/(authenticated)/bookings": [~6,[2]],
		"/(authenticated)/bookmarks": [~7,[2]],
		"/(authenticated)/calendar": [~8,[2]],
		"/(authenticated)/campaigns": [~9,[2]],
		"/(authenticated)/campaigns/[id]": [~10,[2]],
		"/(authenticated)/channel/[id]": [~11,[2]],
		"/(hybrid)/cookie-settings": [16,[3]],
		"/(hybrid)/footer/[slug]": [~17,[3]],
		"/(hybrid)/hub/[slug]": [~18,[3]],
		"/(authenticated)/join/[id]": [~12,[2]],
		"/(authenticated)/notifications": [~13,[2]],
		"/(hybrid)/product/[slug]": [~19,[3]],
		"/(authenticated)/profile": [~14,[2]],
		"/(authenticated)/search": [~15,[2]],
		"/(hybrid)/token": [20,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';